var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"row"},[_c('CustomFileInput',{attrs:{"className":'col-12',"defaultImg":_vm.fullPathFileFromServer(
            _vm.reminderPlan.reminderPlanImagePath,
            _vm.reminderPlan.defaultImg
          ),"deleteFileStatus":_vm.deleteFileStatus &&
          !_vm.reminderPlan.reminderPlanImageIsDefault &&
          _vm.checkPrivilege(_vm.hasReminderPlanDeleteImage())},on:{"changeValue":function($event){_vm.reminderPlan.mediaFile = $event.file},"deleteFile":function($event){return _vm.$emit('deleteFile')}}})],1),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priorityTypeBeforeTimeToken"),"errors":_vm.errors_priorityTypeBeforeTimeToken,"value":_vm.reminderPlan.priorityTypeBeforeTimeToken,"options":_vm.priorityTypeTokenOptions,"title":_vm.$t('ReminderPlans.priorityTypeBeforeTime'),"imgName":'priorityTypes.svg'},on:{"changeValue":function($event){_vm.reminderPlan.priorityTypeBeforeTimeToken = $event;
            _vm.$v.reminderPlan.priorityTypeBeforeTimeToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priorityTypeOnTimeToken"),"errors":_vm.errors_priorityTypeOnTimeToken,"value":_vm.reminderPlan.priorityTypeOnTimeToken,"options":_vm.priorityTypeTokenOptions,"title":_vm.$t('ReminderPlans.priorityTypeOnTime'),"imgName":'priorityTypes.svg'},on:{"changeValue":function($event){_vm.reminderPlan.priorityTypeOnTimeToken = $event;
            _vm.$v.reminderPlan.priorityTypeOnTimeToken.$touch();}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":(_vm.id + "-priorityTypeAfterTimeToken"),"errors":_vm.errors_priorityTypeAfterTimeToken,"value":_vm.reminderPlan.priorityTypeAfterTimeToken,"options":_vm.priorityTypeTokenOptions,"title":_vm.$t('ReminderPlans.priorityTypeAfterTime'),"imgName":'priorityTypes.svg'},on:{"changeValue":function($event){_vm.reminderPlan.priorityTypeAfterTimeToken = $event;
            _vm.$v.reminderPlan.priorityTypeAfterTimeToken.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-fullCode"),"value":_vm.reminderPlan.fullCode,"title":_vm.$t('ReminderPlans.code'),"imgName":'code.svg'},on:{"changeValue":function($event){_vm.reminderPlan.fullCode = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-reminderPlanNameAr"),"errors":_vm.errors_reminderPlanNameAr,"value":_vm.reminderPlan.reminderPlanNameAr,"title":_vm.$t('ReminderPlans.nameAr'),"imgName":'reminderPlans.svg'},on:{"changeValue":function($event){_vm.reminderPlan.reminderPlanNameAr = $event;
            _vm.$v.reminderPlan.reminderPlanNameAr.$touch();}}}),_c('CustomInput',{attrs:{"className":'col-md-6',"id":(_vm.id + "-reminderPlanNameEn"),"errors":_vm.errors_reminderPlanNameEn,"value":_vm.reminderPlan.reminderPlanNameEn,"title":_vm.$t('ReminderPlans.nameEn'),"imgName":'reminderPlans.svg'},on:{"changeValue":function($event){_vm.reminderPlan.reminderPlanNameEn = $event;
            _vm.$v.reminderPlan.reminderPlanNameEn.$touch();}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":(_vm.id + "-reminderPlanNotes"),"value":_vm.reminderPlan.reminderPlanNotes,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.reminderPlan.reminderPlanNotes = $event}}}),_vm._v(" "),_c('CustomCheckbox',{attrs:{"className":'col-md-6',"value":_vm.reminderPlan.reminderPlanActivationStatus,"title":_vm.$t('activationStatus')},on:{"changeValue":function($event){_vm.reminderPlan.reminderPlanActivationStatus = $event}}})],1),_c('ReminderPlansDetailsDataComponet',{attrs:{"reminderPlansDetailsData":_vm.reminderPlan.reminderPlansDetailsDataBeforeTime,"reminderPlansDetailsMsg":_vm.$t('ReminderPlans.detailsBeforeTime'),"id":'BeforeTime',"durationTypeToken":_vm.DURATION_TYPE_TOKENS.BeforeTime}}),_c('ReminderPlansDetailsDataComponet',{attrs:{"reminderPlansDetailsData":_vm.reminderPlan.reminderPlansDetailsDataAfterTime,"reminderPlansDetailsMsg":_vm.$t('ReminderPlans.detailsAfterTime'),"id":'AfterTime',"durationTypeToken":_vm.DURATION_TYPE_TOKENS.AfterTime}})],1),_c('div',{staticClass:"form-actions"},[_c('div',{staticClass:"icon-submit",on:{"click":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('img',{attrs:{"src":require("@/assets/images/check-icon.svg"),"title":_vm.submitName}})]),_c('div',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(("ConfirmCloseSheet-" + _vm.bottomSheetName)),expression:"`ConfirmCloseSheet-${bottomSheetName}`"}],staticClass:"icon-cancel",on:{"click":function($event){$event.preventDefault();}}},[_c('img',{attrs:{"src":require("@/assets/images/cancel-icon.svg"),"title":_vm.$t('cancel')}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }